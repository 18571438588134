<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
let color = [
  "rgba(0, 95, 231, 1)",
  "rgba(255, 120, 0, 1)",
  "rgba(2, 213, 156, 1)",
  "rgba(255, 0, 96, 1)",
  "rgba(125, 10, 255, 1)",
  "rgba(0, 203, 247, 1)",
];
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "value",
          data: this.dataEcharts.xaxisData,
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "category",
          data: this.dataEcharts.yaxis,
        },
        grid: {
          top: "18%",
          left: "20%", //原来是10%，修改为20%
          right: "10%",
          bottom: "24%",
          x: 40,
          x2: 100,
          y2: 150
        },
        series: [
          {
            // data: this.dataEcharts.seriesData,
            data: this.dataEcharts.seriesData.map((item, index) => {
              return {
                value: item,
                itemStyle: {
                  color: color[index],
                },
              };
            }),
            type: "bar",
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    fontSize: 14,
                  },
                },
              },
            },
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>