<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        color: ["#1555E7", "#FF7800"],
        title: {
          // text: '对比数据标题',
          // text: this.title,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        legend: {
          data: this.dataEcharts.legendData,
          textStyle: {
            fontSize: "11",
          },
          // data: ['2011年', '2012年']
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            textStyle: {
              fontSize: "12",
            },
          },
        },
        yAxis: {
          type: "category",
          data: this.dataEcharts.yaxisData,
          axisLabel: {
            textStyle: {
              fontSize: "12",
            },
          },
          // data: ['巴西', '印尼', '美国', '印度', '中国', '世界人口(万)']
        },
        series: [
          {
            // name: '2011年',
            name: this.dataEcharts.standardData.name,
            type: "bar",

            barWidth: 10,
            data: this.dataEcharts.standardData.data,
            label: {
              show: true,
              position: "right",
              textStyle: {
                fontSize: "12",
              },
              formatter: function (params) {
                return params.value;
              },
            },
            // data: [18203, 23489, 29034, 104970, 131744, 630230]
          },
          {
            // name: '2012年',
            name: this.dataEcharts.actualData.name,

            barWidth: 10,
            type: "bar",
            // data: [19325, 23438, 31000, 121594, 134141, 681807]
            data: this.dataEcharts.actualData.data,
            label: {
              show: true,
              textStyle: {
                fontSize: "12",
              },
              position: "right",
              formatter: function (params) {
                return params.value;
              },
            },
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>