<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.dataEcharts.xaxisData,
            axisLabel: {
              fontSize: "14",
              color: "#999999",
              fontFamily: "MicrosoftYaHei",
              fontWeight: 400,
            },
            axisLine: {
                lineStyle: {
                    color: '#CCCCCC'
                }
            },
            axisTick: {
                show: false
            }
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              fontSize: "14",
              color: "#999999",
              fontFamily: "MicrosoftYaHei",
              fontWeight: 400,
            },
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            }
          },
        ],
        series: [
          {
            //柱底圆片
            name: "",
            type: "pictorialBar",
            symbolSize: [32, 12],
            symbolOffset: [0, 6],
            z: 12,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(31, 97, 234, .4)",
                  },
                  {
                    offset: 1,
                    color: "rgba(31, 97, 234, .4)",
                  },
                ]),
              },
            },
            data: this.dataEcharts.seriesData,
          },
          //柱体
          {
            name: "",
            type: "bar",
            barWidth: 32,
            barGap: "0%",
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: "#1555E7",
                    },
                    {
                      offset: 1,
                      color: "rgba(21,85,231,0.35)",
                    },
                  ],
                },
              },
            },
            data: this.dataEcharts.seriesData,
          },
          //柱体
          {
            name: "",
            type: "bar",
            barWidth: 32,
            barGap: "-100%",
            itemStyle: {
              barBorderRadius: [14,14,0,0],
              color: "rgba(0, 80, 240, .07)"
            },
            data: [40,40,40,40],
          },
          //柱顶圆片
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [32, 12],
            symbolOffset: [0, -6],
            z: 12,
            symbolPosition: "end",
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "#15B3E7",
                    },
                    {
                      offset: 1,
                      color: "#15B3E7",
                    },
                  ],
                  false
                ),
              },
            },
            data: this.dataEcharts.seriesData,
          },
          {
            name: "",
            type: "line",
            zlevel: 20,
            smooth: true,
            symbol: "emptyCircle",
            symbolSize: 10,
            lineStyle: {
              normal: {
                show: true,
                color: "#0050F0",
                shadowBlur: 3,
                shadowColor: 'rgba(0,75,206,0.82)',
                shadowOffsetY: 3
              },
            },
            label: {
              show: true,
              position: "top",
              offset: [0, -5],
              color: '#000000',
              fontSize: 14,
              formatter: function (params) {
                return params.data;
              },
            },
            itemStyle: {
              color: "#0050F0",
            },
             data: this.dataEcharts.seriesData,
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>