<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
let colorOne = ['rgba(0, 95, 231, 1)', 'rgba(255, 120, 0, 1)', 'rgba(2, 213, 156, 1)', 'rgba(255, 0, 96, 1)', 'rgba(125, 10, 255, 1)', 'rgba(0, 203, 247, 1)']
let colorTwo = ['rgba(0, 95, 231, .4)', 'rgba(255, 120, 0, .4)', 'rgba(2, 213, 156, .4)', 'rgba(255, 0, 96, .4)', 'rgba(125, 10, 255, .4)', 'rgba(0, 203, 247, .4)']
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      myChart.setOption({
        color: colorOne,
        legend: {
            data: this.dataEcharts.legendData,
            icon: 'circle',
            bottom: 1,
            textStyle: {
                color: '#333',
                fontSize: 14
            },
            itemGap: 40
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.dataEcharts.xaxisData,
            axisLabel: {
              fontSize: "14",
              color: "#999999",
              fontFamily: "MicrosoftYaHei",
              fontWeight: 400,
            },
            axisLine: {
              lineStyle: {
                color: "#CCCCCC",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            scale: true,
            type: "value",
            axisLabel: {
              fontSize: "14",
              color: "#999999",
              fontFamily: "MicrosoftYaHei",
              fontWeight: 400,
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: this.dataEcharts.seriesData.map((item, index) => {
          return {
            name: item.name,
            type: "bar",
            barWidth: 12,
            data: item.data.map((dataD) => {
              return {
                value: dataD,
                label: {
                  show: true,
                  position: dataD > 0 ? "top" : "bottom",
                  offset: dataD > 0 ? [0, -5] : [0, -30],
                  color: "#000000",
                  fontSize: 14,
                },
                itemStyle: {
                  normal: {
                    color: {
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      type: "linear",
                      global: false,
                      colorStops: [
                        {
                          //第一节下面
                          offset: dataD > 0 ? 0 : 1,
                          color: dataD > 0 ? colorOne[index] : colorTwo[index],
                        },
                        {
                          offset: dataD > 0 ? 1 : 0,
                          color: dataD > 0 ? colorTwo[index] : colorOne[index],
                        },
                      ],
                    },
                    barBorderRadius: dataD > 0 ? [14, 14, 0, 0] : [0, 0, 14, 14],
                  },
                },
              };
            }),
          };
        }),
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>