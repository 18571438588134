<template>
    <div class="comHengResult">
        <div class="chartwrap">
            <div class="chartone" v-if="xaxisTitle.length != 0">
                <div class="charttitle">
                    <div class="titleitem" :style="{color: colorList[index],width: widthArr[index]+'px'}" :key="index" v-for="(item, index) in xaxisTitle">
                        {{item}}
                    </div>
                </div>
                <div class="charttypeline">
                    <div class="chartlineitem" :style="{backgroundColor: colorList[index],width: widthArr[index] + 'px'}" :key="index" v-for="(item, index) in xaxisTitle"></div>
                </div>
                <div class="charlinebody">
                    <div class="charlinecontent" :style="{width: barWidth + 'px'}"></div>
                    <div class="chartlinenum" :style="{left: (barWidth + 10) + 'px'}">{{seriesData[0]}}</div>
                </div>
                <div class="charnumwrap">
                    <div class="charnumitem" :style="{width: widthArr[index]+'px'}" v-for="(item,index) in xaxisData" :key="index">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="chartone" v-else-if="title.indexOf('追求') != -1">
                <div class="charlinebody" style="background-color: #FFE9E5">
                    <div class="charlinecontent" :style="{width: barWidth + 'px', backgroundColor: '#FE2400'}"></div>
                    <div class="chartlinenum" :style="{left: (barWidth + 10) + 'px', color: '#FE2400'}">{{seriesData[0]}}</div>
                </div>
                <div class="charnumwrap">
                    <div class="charnumitem" v-for="(item,index) in xaxisData" :key="index">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="chartone" v-else>
                <div class="charlinebody" style="background-color: #FDF5DB">
                    <div class="charlinecontent" :style="{width: barWidth + 'px', backgroundColor: '#FABC00'}"></div>
                    <div class="chartlinenum" :style="{left: (barWidth + 10) + 'px', color: '#FABC00'}">{{seriesData[0]}}</div>
                </div>
                <div class="charnumwrap">
                    <div class="charnumitem" v-for="(item,index) in xaxisData" :key="index">
                        {{item}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'comHengResult',
    props: {
        title: {
            type: String,
            defaul: () => ''
        },
        xaxisData: {
            type: Array,
            defaul: () => []
        },
        xaxisTitle: {
            type: Array,
            defaul: () => []
        },
        seriesData: {
            type: Array,
            defaul: () => []
        },
    },
    data() {
        return {
            colorList: ['#00B050', '#FABC00', '#FE2400', '#22E07C', '#00CBF7'],
            barWidth: 0,
            widthArr: []
        }
    },
    mounted() {
        this.calculationWidth(this.xaxisData, this.seriesData)
    },
    methods: {
        calculationWidth(all,now) {
            let allnum = ((all[all.length - 1] * 100) - (all[0] * 100)) / 100
            // 计算每一个数占多长距离
            let domwidth = document.getElementsByClassName('charlinebody')[0].clientWidth
            let oneWidht = domwidth / allnum
            // 计算每一个分类站多长距离
            this.widthArr = []
            all.forEach((i, index) => {
                let num = (all[index + 1] - i) * oneWidht
                if (index != all.length - 1) {
                    this.widthArr.push(num)
                }
            });
            let nownum = ((now[0] * 100) -  (all[0] * 100)) / 100
            this.barWidth = parseFloat(((nownum * 100) / (allnum * 100))) * domwidth
            console.log(this.barWidth)
            console.log(this.widthArr)
        },
    }
}
</script>

<style scoped>
.comHengResult {
   width: 95%;
}
.chartwrap {
    width: 100%;
    margin: 0.4rem auto;
}
.charttitle,
.charttypeline {
    width: 100%;
    display: flex;
}
.titleitem {
    width: 190px;
    text-align: center;
    line-height: 0.28rem;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    /* white-space: nowrap; */
}
.chartlineitem {
    width: 190px;
    height: 0.18rem;
}
.charlinebody {
    width: 100%;
    height: 0.56rem;
    background: #E8F0FF;
    display: flex;
    align-items: center;
    position: relative;
}
.charlinecontent {
    width: 470px;
    height: 0.56rem;
    background-color: #005FE7;
}
.chartlinenum {
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #005FE7;
    line-height: 48px;
    position: absolute;
    left: 480px;
}
.charnumwrap {
    width: 100%;
    height: 41px;
    align-items: center;
    margin-left: -10px;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.charnumitem {
    width: 2em;
    font-size: 0.24rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
}
</style>