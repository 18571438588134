<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
let colorOne = [
  "rgba(0, 95, 231, 1)",
  "rgba(255, 120, 0, 1)",
  "rgba(2, 213, 156, 1)",
  "rgba(255, 0, 96, 1)",
  "rgba(125, 10, 255, 1)",
  "rgba(0, 203, 247, 1)",
];
let colorTwo = [
  "rgba(0, 95, 231, .4)",
  "rgba(255, 120, 0, .4)",
  "rgba(2, 213, 156, .4)",
  "rgba(255, 0, 96, .4)",
  "rgba(125, 10, 255, .4)",
  "rgba(0, 203, 247, .4)",
];
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        color: ["#7179CB", "#4CCEFE"],
        title: {
          text: this.dataEcharts.title,
          textStyle: {
            fontSize: 16,
            padding: "10px",
          },
        },
        legend: {
          // data: ["总量", "分量"],
          show: false,
        },
        tooltip: {},

        xAxis: {
          data: this.dataEcharts.xaxisData,
          position: "center",
          axisPointer: {
            //坐标轴指示器
            type: "shadow", //在tooltip的cross基础上，增加阴影类型的X轴指示器
          },
          axisLabel: {
            interval: 0,
            rotate: 30,
          },
        },
        yAxis: {
          splitArea: { show: false },
        },

        series: [
          {
            barWidth: 12,
            position: "center",
            // data:  ["47", "59", "95", "74",],
            data: this.dataEcharts.firstSeriesData,
            name: "标准值",
            type: "bar",
            label: {
              show: true,
              position: "top",
              formatter: function (params) {
                return params.value;
              },
            },
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: colorOne[0],
                    },
                    {
                      offset: 1,
                      color: colorTwo[0],
                    },
                  ],
                },
                barBorderRadius: [14, 14, 0, 0],
              },
            },
          },
          {
            // barGap: "-100%" /*这里设置包含关系，只需要这一句话*/,
            barWidth: 12,
            barGap: "20%", //柱图间距
            // data: ["27", "24", "43", "10", ],
            data: this.dataEcharts.secondSeriesData,
            name: "当前值",
            type: "bar",
            label: {
              show: true,
              position: "top",
              formatter: function (params) {
                return params.value;
              },
            },
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: colorOne[1],
                    },
                    {
                      offset: 1,
                      color: colorTwo[1],
                    },
                  ],
                },
                barBorderRadius: [14, 14, 0, 0],
              },
            },
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>