<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        series: [
          {
            type: "gauge",
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: this.dataEcharts.maxValue,
            splitNumber: 2,
            itemStyle: {
              // color: 'orange',
              shadowColor: "orange",
              // shadowBlur: 1,
              // shadowOffsetX: 2,
              // shadowOffsetY: 2
            },
            progress: {
              show: true,
              roundCap: true,
              width: 20,
            },
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",
              width: 11,
              offsetCenter: [0, "5%"],
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 18,
              },
            },
            axisTick: {
              splitNumber: 5,
              // lineStyle: {
              //   width: 15,
              //   color: 'orange'
              // }
            },
            splitLine: {
              length: 12,
              lineStyle: {
                width: 0,
                color: "#999",
              },
            },
            axisLabel: {
              distance: 30,
              color: "#000",
              fontSize: 16,
            },
            title: {
              show: false,
            },
            detail: {
              backgroundColor: "#fff",
              width: "60%",
              lineHeight: 20,
              height: 40,
              borderRadius: 8,
              offsetCenter: [0, "35%"],
              valueAnimation: true,
              formatter: function (value) {
                return "{value|" + value.toFixed(0) + "}";
              },
              rich: {
                value: {
                  fontSize: 20,
                  fontWeight: "bolder",
                  // color: 'orange'
                },
                unit: {
                  fontSize: 20,
                  color: "#999",
                  padding: [0, 0, -20, 10],
                },
              },
            },
            data: [
              {
                value: this.dataEcharts.currentValue,
              },
            ],
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>