<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let colorList = [
        "#1555E7",
        "#FF0060",
        "#FF7200",
        "#FFC000",
        "#00D2FF",
        "#00FB7C",
        "#7800FF",
        '#DA00FB',
        '#FF2020',
        '#0089FE'
      ];
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        title: {
          left: "center",
        },
        tooltip: {
          trigger: "item",
          show: false,
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["24%", "45%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              // show: true,
              // position: "outside",
              // formatter: '{a|{b}：{d}%}\n{hr|}',
              // formatter(v) {
              //   console.log(v);
              //   let text = v.name;
              //   return text.length < 4
              //     ? text + v.data.value
              //     : `${text.slice(0, 4)}\n${text.slice(4)}${v.data.value}`;
              // },
              // rich: {
              //   hr: {
              //     backgroundColor: "t",
              //     borderRadius: 3,
              //     width: 3,
              //     height: 3,
              //     padding: [3, 3, 0, -12],
              //   },
              //   a: {
              //     padding: [-30, 15, -20, 15],
              //   },
              // },
            },
            labelLine: {
              normal: {
                length: 2,
                length2: 2,
                lineStyle: {
                  width: 1,
                },
              },
            },
            data: this.dataEcharts.seriesData,
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  }
};
</script>

<style>

</style>