<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        legend: {
          // data: ['张三', '李四']
          data: this.dataEcharts.legendData,
        },
        radar: [
          {
            axisLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.5)",
              },
            },
          },
          {
            radius: 100,
            indicator: this.dataEcharts.indicatorData,
            // indicator: [
            //     { text: '语文', max: 150 },
            //     { text: '数学', max: 150 },
            //     { text: '英语', max: 150 },
            //     { text: '物理', max: 120 },
            //     { text: '化学', max: 108 },
            //     { text: '生物', max: 72 }
            // ],
            name: {
              // 字体颜色
                textStyle: {
                    color: "#333333",
                },
            },
          },
        ],
        series: [
          {
            // name: '成绩单',
            type: "radar",
            radarIndex: 1,
            data: [
              {
                value: this.dataEcharts.standardData.data,
                // name: '张三',
                name: this.dataEcharts.standardData.name,
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  },
                },
              },
              {
                value: this.dataEcharts.actualData.data,
                // value: [90, 113, 140, 30, 70, 60],
                // name: '李四',
                name: this.dataEcharts.actualData.name,
                label: {
                  show: true,
                  position: "left",
                  formatter: function (params) {
                    return params.value;
                  },
                },
                areaStyle: {
                  opacity: 0.9,
                  color: new echarts.graphic.RadialGradient(0.5, 0.5, 1, [
                    {
                      color: "#B8D3E4",
                      offset: 0,
                    },
                    {
                      color: "#72ACD1",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>