<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        xAxis: {
          type: "category",
          data: this.dataEcharts.xaxisData,
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            //柱底圆片
            name: "",
            type: "pictorialBar",
            symbolSize: [20, 10],
            data: this.dataEcharts.seriesData.map((i) => {
              return {
                value: i,
                symbolOffset: i > 0 ? [0, 6] : [0, -6],
                z: 12,
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgba(89,211,255,1)",
                      },
                      {
                        offset: 1,
                        color: "rgba(23,237,194,1)",
                      },
                    ]),
                  },
                },
              };
            }),
          },

          //柱体
          {
            name: "",
            type: "bar",
            barWidth: 20,
            barGap: "0%",
            itemStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  type: "linear",
                  global: false,
                  colorStops: [
                    {
                      //第一节下面
                      offset: 0,
                      color: "rgba(0,255,245,0.5)",
                    },
                    {
                      offset: 1,
                      color: "#43bafe",
                    },
                  ],
                },
              },
            },

            data: this.dataEcharts.seriesData,
          },

          //柱顶圆片
          {
            name: "",
            type: "pictorialBar",
            symbolSize: [20, 10],
            data: this.dataEcharts.seriesData.map((i) => {
              return {
                value: i,
                symbolOffset: i > 0 ? [0, -6] : [0, 6],
                z: 12,
                symbolPosition: "end",
                itemStyle: {
                  normal: {
                    color: new echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "rgba(89,211,255,1)",
                        },
                        {
                          offset: 1,
                          color: "rgba(23,237,194,1)",
                        },
                      ],
                      false
                    ),
                  },
                },
              };
            }),
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>