<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      // 绘制图表
      myChart.setOption({
        // color: ['#3398DB'],
        title: {
          text: this.dataEcharts.title,
          textStyle: {
            fontSize: 16,
            color: "#333333",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.dataEcharts.xaxisData,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "数值",
            type: "bar",
            // barWidth: '60%',
            barWidth: 30,
            barMaxWidth: 30,
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              },
              position: "top",
            },
            data: this.dataEcharts.seriesData,
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>