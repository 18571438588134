<template>
  <div id="hengzhuCharts">
    <h3>学习风格</h3>
    <div class="row-item" v-for="(item, index) in left" :key="index">
      <div class="row-wrap">
        <span class="text-title">{{ leftData[index] }}{{ left[index] }}%</span>
        <div style="display: inline-block">
          <span
            class="text-zhu text-zhl"
            :style="{
              width: left[index] + '%',
              background: left[index] >= 50 ? colorOne[index] : colorTwo[index],
            }"
          ></span
          ><span
            class="text-zhu text-zhr"
            :style="{
              width: 100 - left[index] + '%',
              background:
                100 - left[index] > 50 ? colorOne[index] : colorTwo[index],
            }"
          ></span>
        </div>
        <span class="text-title"
          >{{ 100 - left[0] }}%{{ righttData[index] }}</span
        >
      </div>
    </div>
    <h4>(比例越大说明你这方面的特征越明显)</h4>
  </div>
</template>

<script>
export default {
  name: "hengzhuchart",
  data() {
    return {
      colorOne: ["#005FE7", "#FF7800", "#00B050", "#FF0060"],
      colorTwo: ["#95C1FF", "#FFBC80", "#88FDBD", "#FFA5C7"],
      leftData: ["活跃(E)", "感悟(S)", "视觉(V)", "序列(J)"],
      righttData: ["沉思(I)", "直觉(N)", "言语(A)", "综合(P)"],
    };
  },
  props: ["left"],
};
</script>

<style lang="less">
#hengzhuCharts {
  text-align: center;
  h3 {
    font-size: 0.3rem;
    padding: 0.2rem 0;
  }
  h4 {
    font-size: 0.18rem;
    color: #666;
  }
  .row-item {
    margin-bottom: 0.3rem;
    .row-wrap {
      display: flex;
      align-items: center;
      div {
        width: 100%;
        margin: 0 12px;
      }
      .text-title {
        font-size: 0.16rem;
        line-height: 0.8rem;
        white-space: nowrap;
        width: 1.2rem;
      }
      .text-zhu {
        display: inline-block;
        vertical-align: middle;
        height:  0.8rem;
      }
      .text-zhl {
        background: blue;
        width: 40%;
      }
      .text-zhr {
        background: gray;
        width: 60%;
      }
      .text-zhul {
        background: green;
        width: 40%;
      }
      .text-zhur {
        width: 60%;
        background: yellow;
      }
    }
  }
}
</style>