<template>
  <div>
    <div
      ref="echarts"
      :style="{ width: '100%', height: '6rem' }"
      style="margin: 0 auto"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    this.renderEcharts();
  },
  methods: {
    // 渲染饼柱状图
    renderEcharts() {
      let echarts = require("echarts");
      let myChart = echarts.init(this.$refs.echarts);
      // 绘制图表
      myChart.setOption({
        title: {},
        legend: {
          // data: ["预算分配（Allocated Budget）"],
          data: this.dataEcharts.legendData,
        },
        radar: {
          // shape: 'circle',
          indicator: this.dataEcharts.indicator,
          axisLine: {
            lineStyle: {
              color: "#005fe7",
            },
          },
          radius: 100,
        },
        series: [
          {
            name: "",
            type: "radar",
            data: this.dataEcharts.seriesData,
            label: {
              normal: {
                show: true,
                formatter: function (params) {
                  return params.value;
                },
              },
            },
            lineStyle: {
              normal: {
                color: "#005FE7",
                type: "solid",
                width: 3,
              },
              areaStyle: {
                normal: {
                  color: "rgba(6, 222, 249,0.3)",
                },

                emphasis: {
                  color: "rgba(6, 222, 249,0.5)",
                },
              },
              emphasis: {},
            },
          },
        ],
      });
    },
  },
  props: {
    dataEcharts: {
      type: Object,
    },
  },
};
</script>

<style>
</style>